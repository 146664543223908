<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'NotificationList',
              }"
              >系統通知</b-breadcrumb-item
            >
            <b-breadcrumb-item active>通知管理</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-overlay :show="isLoading">
      <div class="card">
        <div class="card-body">
          <div class="row mb-3 align-items-center justify-content-between">
            <div class="col-md-auto">
              <h4 class="font-weight-bold mr-auto">通知管理</h4>
            </div>
            <div class="col-md-6 d-flex align-items-center">
              <router-link
                v-if="checkPermission([consts.NOTIFICATION_MODIFY])"
                class="flex-shrink-0 mr-3"
                :to="{ name: 'NotificationCreate' }"
              >
                <button class="btn btn-primary">＋新增通知</button>
              </router-link>

              <b-input-group>
                <b-form-input
                  type="text"
                  placeholder="搜尋通知名稱"
                  v-model="keyword"
                  v-on:keyup.enter="fetchNotifications"
                ></b-form-input>

                <b-input-group-append>
                  <b-button @click="fetchNotifications"
                    ><i class="fa fa-search"></i
                  ></b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>

          <ul class="nav nav-tabs">
            <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                @click="changeTab(TABS.WAITING)"
                href="#"
                :class="{ active: activeTab === TABS.WAITING }"
                >等待發送</a
              >
            </li>
            <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                @click="changeTab(TABS.COMPLETED)"
                href="#"
                :class="{ active: activeTab === TABS.COMPLETED }"
                >已完成</a
              >
            </li>
          </ul>

          <b-table
            class="mb-5"
            striped
            hover
            responsive
            :items="computedNotifications"
            :fields="fields"
          >
            <template #cell(notify_type)="data">
              <i
                class="fa fa-exclamation-circle"
                v-if="data.item.notify_type === 'important'"
              ></i>
            </template>

            <template #cell(actions)="data">
              <b-button
                v-if="
                  checkPermission([consts.NOTIFICATION_MODIFY]) &&
                  data.item.canEdit
                "
                variant="inverse-warning"
                :to="{
                  name: 'NotificationForm',
                  params: { notification_id: data.item.id },
                }"
                >編輯</b-button
              >

              <b-button
                v-if="
                  checkPermission([consts.NOTIFICATION_MODIFY]) &&
                  data.item.canEdit
                "
                class="ml-2"
                variant="inverse-danger"
                @click="handleDeleteNotification(data.item)"
                >刪除</b-button
              >

              <b-button
                variant="inverse-info"
                class="ml-2"
                :to="{
                  name: 'NotificationView',
                  params: { notification_id: data.item.id },
                }"
                >查看</b-button
              >
            </template>
          </b-table>

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="10"
            align="center"
            @change="fetchNotifications"
          ></b-pagination>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import format from "date-fns/format";
import notificationApi from "@/apis/notification";
import checkPermission from "@/mixins/checkPermission";

export const TABS = {
  WAITING: "waiting",
  COMPLETED: "completed",
};

export default {
  mixins: [checkPermission],
  data() {
    return {
      TABS,
      notifications: [],
      isLoading: false,
      activeTab: this.$route.query.tab || TABS.WAITING,
      keyword: "",
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "title",
          label: "通知名稱",
        },
        {
          key: "notify_type",
          label: "標註",
        },
        {
          key: "displayDate",
          label: "發送時間",
        },
        {
          key: "actions",
          label: "操作",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("general", ["organization"]),
    computedNotifications() {
      return this.notifications.map((n) => {
        n.displayDate = format(new Date(n.publish_at), "yyyy-MM-dd HH:mm");
        n.canEdit = new Date(n.publish_at) > new Date();
        return n;
      });
    },
  },
  created() {
    this.fetchNotifications();
  },
  methods: {
    async fetchNotifications(page) {
      this.isLoading = true;
      try {
        const params = {
          compare: this.activeTab === TABS.WAITING ? "gt" : "lt",
          publish_at: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
          page: page || this.currentPage,
        };
        if (this.keyword) params.keyword = this.keyword;
        const { data } = await notificationApi.getNotifications(
          this.organization.id,
          params
        );

        this.notifications = data.data;
        this.totalRows = data.meta.total;
      } catch (error) {
        console.error(error);
        alert("取得通知資料錯誤");
      }
      this.isLoading = false;
    },

    handleDeleteNotification(notification) {
      this.$swal
        .fire({
          type: "warning",
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>通知名稱：${notification.title}</div>
                <div>發送時間：${notification.displayDate}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              const response = await notificationApi.deleteNotification(
                notification.id
              );
              if (response.status === 200) {
                await this.$swal.fire({
                  title: "成功",
                  type: "success",
                  text: "刪除成功",
                });
                await this.fetchNotifications();
              }
            } catch (error) {
              console.log("");
            }
          }
        });
    },

    changeTab(tab) {
      this.activeTab = tab;
      this.keyword = "";
      this.currentPage = 1;
      this.fetchNotifications();
    },
  },
};
</script>
